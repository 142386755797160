import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Page from '../templates/Page'
import Popup from '../components/Popup'
import Spinner from '../components/ui/Spinner'
import {
  H2,
  H3,
  Input,
  Button,
  Center,
  colors,
  Spacer,
  Textarea,
  TextWrapper,
  TwoColumnGrid,
} from '../utils/styles'

const PopupTitle = styled.h2`
  margin-top: 0;
`

const defaultForm = {
  email: '',
  name: '',
  country: 'Germany',
  quantity: 1,
  notes: '',
}

const POST_URL =
  process.env.NODE_ENV === 'production'
    ? '/service-request.php'
    : 'https://nighttec.de/service-request.php'

const mode = process.env.NODE_ENV === 'production' ? 'same-origin' : 'no-cors'

const ServicePage = ({ data: { heroImage } }) => {
  const [isVisible, setLightboxVisible] = useState(false)
  const [form, setForm] = useState(defaultForm)
  const [status, setStatus] = useState(null)
  const { t } = useTranslation()
  const popupRef = useRef(null)
  let popupElement = null

  useLayoutEffect(() => {
    popupElement = popupRef.current

    if (isVisible) {
      disableBodyScroll(popupElement)
    } else {
      enableBodyScroll(popupElement)
    }

    return () => clearAllBodyScrollLocks()
  }, [isVisible])

  const handleForm = ({ target }) => {
    const { name, value } = target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const submit = e => {
    e.preventDefault()
    const body = JSON.stringify(form)
    setStatus('loading')

    fetch(POST_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
      mode,
    }).then(res => {
      if (!res.ok) setStatus('error')
      setStatus('success')
      setForm(defaultForm)
      setTimeout(() => {
        setStatus(null)
        setLightboxVisible(false)
      }, 4000)
    })
  }

  const buyButtonContent = () => {
    if (status === 'loading') return <Spinner />
    if (status === 'success') return <Trans>Sent</Trans>
    if (status === 'error') return 'Error'
    return <Trans>Submit</Trans>
  }

  const buttonBackgroundColor =
    status === 'success'
      ? colors.androidGreen
      : status === 'error'
      ? colors.fireEngineRed
      : 'black'

  return (
    <Page
      title={t('WE OFFER ALL SERVICE')}
      subtitle={t('YOU NEED FOR YOUR NIGHTVISION')}
      headerImage={heroImage}
    >
      <Popup
        ref={popupRef}
        visible={isVisible}
        toggle={() => {
          setLightboxVisible(false)
          clearAllBodyScrollLocks()
        }}
      >
        <Center>
          <PopupTitle>
            <Trans>Service Request</Trans>
          </PopupTitle>
        </Center>

        <form
          onSubmit={submit}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <label htmlFor="name">
            Name
            <br />
            <Input
              required
              type="text"
              id="name"
              name="name"
              placeholder={t('NAME_PLACEHOLDER')}
              value={form.name}
              onChange={handleForm}
            />
          </label>
          <br />
          <label htmlFor="email">
            <Trans>E-Mail Adress</Trans>
            <br />
            <Input
              required
              type="email"
              id="email"
              name="email"
              value={form.email}
              onChange={handleForm}
            />
          </label>
          <br />
          <label htmlFor="notes">
            <Trans>Message</Trans>
            <br />
            <Textarea
              type="text"
              id="notes"
              name="notes"
              maxLength={1000}
              value={form.notes}
              onChange={handleForm}
            />
          </label>
          <br />
          <br />
          <Button
            backgroundColor={buttonBackgroundColor}
            type="submit"
            disabled={status === 'loading'}
            role="button"
          >
            {buyButtonContent()}
          </Button>
        </form>
      </Popup>
      <Center>
        <H2 color={colors.androidGreen}>
          <Trans>Services</Trans>
        </H2>
      </Center>
      <Spacer height="2rem" />
      <TwoColumnGrid>
        <TextWrapper>
          <H3>Purge</H3>
          <p>
            Having trouble with your device fogging up at night?
            <br /> Purging makes your device immune against it.
            <br />
            <br /> Choose this service for a better performance.
          </p>
          <div style={{ width: 'auto' }}>
            <Button
              backgroundColor={colors.androidGreen}
              onClick={() => setLightboxVisible(true)}
            >
              Ask for Prices
            </Button>
          </div>
        </TextWrapper>
        <div data-inviewport="fade-in-right">
          <StaticImage
            src="../images/nightvision-purging.jpeg"
            alt="Nightvision purging"
            layout="fullWidth"
            placeholder="tracedSVG"
          />
        </div>

        <TextWrapper>
          <H3>Repair</H3>
          <p>
            Your nightvision device needs maintenance service or repair?
            <br />
            <br /> Send us a request to see if we can help.
          </p>
          <div style={{ width: 'auto' }}>
            <Button
              backgroundColor={colors.androidGreen}
              onClick={() => setLightboxVisible(true)}
            >
              Ask for Prices
            </Button>
          </div>
        </TextWrapper>

        <div data-inviewport="fade-in-left">
          <StaticImage
            src="../images/nightvision-purging-2.jpeg"
            alt="Nightvision purging"
            layout="fullWidth"
            placeholder="tracedSVG"
          />
        </div>
      </TwoColumnGrid>
    </Page>
  )
}

export const query = graphql`
  query ($language: String!) {
    heroImage: file(
      name: { eq: "service_header" }
      sourceInstanceName: { eq: "images" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          aspectRatio: 4
        )
      }
    }
    i18nLocales: allLocale(
      filter: { ns: { in: ["common", "service"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ServicePage
